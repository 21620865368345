export const HOME = '/';
export const HOMECOMPANY = '/menu-company';
export const PUBLICATIONS = '/publications';
export const REGISTER = '/registro';
export const RESTOREPASS = '/restablecer-contraseña';
export const NOTIFICATIONPASS = '/notificacion-contraseña';
export const NEWPASSWORD = '/new-password';
export const NOTIFICATIONREQUESTSENT = '/solicitud-enviada';
export const NOTIFICATIONUPDATEPASSWORD = '/update-password'
export const LOGIN = '/inicio-sesion';
export const WELCOME = '/inicio';
export const INFO = '/info';
export const INFOADRESS = '/info-direccion';
export const INFOACADEMIC = '/informacion-academica';
export const INFOEXPERIENCE = '/info-experiencia';
export const REGISTRATION_REQUEST = '/registration-request';
export const MAINTENANCE_USER = '/maintenance-user';
export const PROFILEUPDATESUCCESSFUL = '/informacion-completada-con-exito';

export const CANCEL_ALERT = '/cancel-alert'
export const NOTIFICATION_ALERT = '/notification-alert'

export const SOLICITUDES = '/solicitudes';

// export const NEWPASSWORD = '/nueva-contraseña/:token'
