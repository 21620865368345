import React from 'react'

import './index.css'

const Spinner = () => {

    return (
        <div className="loader"></div>
    )
}

export default Spinner